<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12">
        <button type="button" :disabled="disabled" class="font-weight-bold btn btn-xs border-secondary w-100 text-left"  data-toggle="modal" :data-target="'#'+id" style="min-height: 30px;">
          <template v-if="Array.isArray(selectedTexts)">
            <span class="badge badge-secondary badge-pill mt-1 mr-1 p-2 mb-1 d-inline-block" v-for="text in selectedTexts">{{text}}</span>
          </template>
          <template v-else>{{selectedTexts}}</template>
        </button>
      </div>
    </div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" :id="id" tabindex="-2" :aria-labelledby="id + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">SELECT: <strong class="font-weight-bolder">{{entity.toUpperCase()}}</strong></h5>
          </div>
          <div class="modal-body" style="min-height: 200px; max-height: 450px; overflow-y: auto;">
            <div class="row align-items-center" v-for="item in items">
              <div class="col-lg-12">
                <input v-model="itemSelected" type="checkbox" class="align-middle" :value="item[defValue]" name="item">
                <span class="mx-2 align-middle" style="font-size: 18px;">
                  {{item[name] ? item[name].toUpperCase() : ''}}
                </span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
              <div class="text-right">
                <button type="button" class="btn btn-secondary btn-sm" @click="saveSelected">Close</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MultiSelectCustom",
  data(){
    return{
      itemSelected:this.selected,
    }
  },
  props:{
    items:{
      type:Object|Array,
      default:null,
    },
    id:{
      type:String,
      default: 'multiSelect'
    },
    name:{
      type:String,
      default:'name',
    },
    defValue:{
      type:String,
      default:'id',
    },
    entity:{
      type:String,
      default:'ENTITIES'
    },
    selected:{
      type:Object|Array,
      default:null,
    },
    disabled:{
      type: Boolean,
      default:false
    }
  },
  computed:{
    selectedTexts(){
      if(this.itemSelected.length){
        return this.items.filter(item => this.itemSelected.includes(item.id)).map(item => {
          return item.name.toUpperCase();
        })
      }
      return 'SELECT ' + this.entity.toUpperCase();
    }
  },
  methods:{
    saveSelected(){
      $('#'+this.id).modal('hide');
      this.$emit('doneSelected',{selected:this.itemSelected});
    }
  },
  watch:{
    selected(val){
      this.itemSelected=val
    }
  }
}
</script>

<style scoped>
    input[type='checkbox']{
      width: 20px;
      height: 20px;
    }
    .modal-dialog{
      overflow-y: initial !important
    }
    .modal-body{
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }
</style>
